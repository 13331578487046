import React, { Fragment, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
const navigationManifest = [
  {
    title: 'Séance',
    links: [
      {
        title: 'Informations',
        to: '/hypnose-seance'
      },
      {
        title: 'Arrêt du tabac',
        to: '/hypnose-arret-du-tabac'
      },
      {
        title: 'Cohérence cardiaque',
        to: '/coherence-cardiaque'
      },
      {
        title: 'Access bars',
        to: '/access'
      },
      {
        title: 'PNL',
        to: '/hypnose-pnl'
      }
    ]
  },
  {
    title: 'Contact',
    to: '/contact'
  },
  {
    title: 'Prestations',
    to: '/prestations'
  },
  {
    title: 'FAQ',
    to: '/faq'
  }
];
const isBrowser = typeof window !== 'undefined';

const Header = () => {
  //* * Hook part **/
  const headerRef = useRef();
  const handleScroll = () => {
    if (isBrowser) {
      headerRef.current.classList[window.pageYOffset ? 'remove' : 'add']('top');
    }
  };

  useEffect(() => {
    if (isBrowser) {
      window.pageYOffset && headerRef.current.classList.remove('top');
      window.addEventListener('scroll', handleScroll, true);
      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
    }
  }, []);

  //* * Function part **/
  const handleBurger = ({ currentTarget, target: { id } }) => {
    if (['hamburger-overlay', 'hamburger-button', 'hamburger-bars'].includes(id)) {
      currentTarget.classList.toggle('hamburger-activated');
    }
  };
  const getActivePage = (pathName) => {
    let locationPathname;
    if (isBrowser) {
      locationPathname = window.location.pathname;
    }
    const classProperties = locationPathname === '/' ? 'hide' : 'active';
    return pathName === locationPathname ? classProperties : '';
  };
  const getActiveSection = (links = []) => {
    let locationPathname;
    if (isBrowser) {
      locationPathname = window.location.pathname;
    }
    return links.some(el => el?.to === locationPathname) ? 'active' : '';
  };
  const mapNavigation = (deviceMobile = false) => ({ title, to, links }, key) => {
    // const ref = useRef();
    return (
      <Fragment key={key}>
        {/* IF two level */}
        {links &&
          <li className={`dropdown ${getActiveSection(links)}`} role='button' tabIndex={0} aria-hidden={!deviceMobile} onClick={(e) => deviceMobile ? e.currentTarget.querySelector('.dropdown-content').classList.toggle('active') : console.log('Dodo')}>
            <a href='#' role='menuitem'>{title} <i className='fas fa-caret-down' /></a>
            <div className='dropdown-content'>
              {links.map(({ title: titleSecond, to: toSecond }, keySecond) => {
                return (
                  <Fragment key={`${key}${keySecond}`}>
                    <p className={getActivePage(toSecond)}><Link to={toSecond}>{titleSecond}</Link></p>
                  </Fragment>
                );
              })}
            </div>
          </li>}
        {/* IF One level */}
        {!links && <li key={key} className={getActivePage(to)}><Link to={to}>{title}</Link></li>}
      </Fragment>
    );
  };

  //* * Render part **/
  return (
    <header id='header' className='top' ref={headerRef}>
      <Link to='/' id='logo' role='presentation' aria-hidden='true'>
        <i className='spriteLogo sprite-logoblue' />
        <i className='spriteLogo sprite-logowhite' />
      </Link>
      <div id='hamburger' onClick={handleBurger} role='button' tabIndex={0} aria-hidden='true'>
        <div id='hamburger-content'>
          <nav>
            <ul>
              {navigationManifest.map(mapNavigation())}
              <li><a href='https://www.google.fr/search?rlz=1C1CHBF_frFR772FR772&ei=01PaWteNIoTkUrj0itgB&q=hypnose+venture&oq=hypnose+venture&gs_l=psy-ab.3...0.0.0.2768.0.0.0.0.0.0.0.0..0.0....0...1..64.psy-ab..0.0.0....0.tNQyZ6h6es8#lrd=0x47e6405e2a40f9b3:0x11180684f019fd7b,3,,,' target='_blank' rel='noreferrer'>Avis</a></li>
              <li aria-hidden='true'>
                <a href='https://www.facebook.com/hypnoseventure/' target='_blank' aria-label='facebook' rel='noreferrer'><i className='fab fa-facebook-f' /></a>
              </li>
            </ul>
          </nav>
        </div>
        <button id='hamburger-button' role='menu' aria-label='hamburger' aria-hidden='true'><i id='hamburger-bars' className='fas fa-bars' /></button>
        <div id='hamburger-sidebar'>
          <div id='hamburger-sidebar-header' />
          <div id='hamburger-sidebar-body'>
            <nav>
              <ul>
                {navigationManifest.map(mapNavigation(true))}
                <li><a href='https://www.google.fr/search?rlz=1C1CHBF_frFR772FR772&ei=01PaWteNIoTkUrj0itgB&q=hypnose+venture&oq=hypnose+venture&gs_l=psy-ab.3...0.0.0.2768.0.0.0.0.0.0.0.0..0.0....0...1..64.psy-ab..0.0.0....0.tNQyZ6h6es8#lrd=0x47e6405e2a40f9b3:0x11180684f019fd7b,3,,,' target='_blank' rel='noreferrer'>Avis</a></li>
                <li aria-hidden='true'><a href='https://www.facebook.com/hypnoseventure/' target='_blank' aria-label='facebook' rel='noreferrer noopener'><i className='fab fa-facebook-f' /></a></li>
              </ul>
            </nav>
          </div>
        </div>
        <div id='hamburger-overlay' />
      </div>
    </header>
  );
};

export default Header;
