import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Breadcrumb from './breadcrumb';

const Footer = () => {
  const [loaded] = useState(true);
  // useEffect(() => {
  //   const timeout = setTimeout(() => setLoaded(loaded => !loaded), 500);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  useEffect(() => {
    if (!document.getElementById('axeptio')) {
      const el = document.createElement('script');
      el.setAttribute('src', 'https://static.axept.io/sdk.js');
      el.setAttribute('type', 'text/javascript');
      el.setAttribute('async', 'true');
      el.setAttribute('data-id', '61c5ca89c63d221caa835caa');
      el.setAttribute('id', 'axeptio');
      if (document.body !== null) {
        document.body.appendChild(el);
      }
    }
  }, []);

  return (
    <>
      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <h4>À propos</h4>
              <hr />
              <p>
                L'hypnose thérapeutique est une thérapie brève. Son champ d’action est assez vaste, phobie, arrêt du tabac, manque de confiance en soi...
              </p>
              <Link to='https://www.doctolib.fr/hypnotherapeute/paris/francine-lampin' target='_blank' rel='noreferrer noopener'>
                <p>Prendre un rendez-vous:</p>
                <StaticImage src='../img/event.png' placeholder='blurred' alt='event' width={64} height={64} />
              </Link>
              <Link to='/certification'>
                <p>Mes certifications:</p>
                <StaticImage src='../img/diploma.png' placeholder='blurred' alt='Diplome' width={64} height={64} />
              </Link>
            </div>

            <div className='col-md-4'>
              <h4>Liens</h4>
              <hr />
              <ul>
                <li><Link to='/hypnose-arret-du-tabac'>Arrêt du tabac</Link></li>
                <li><Link to='/prestations'>Prestations</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
                <li><Link to='/faq'>FAQ</Link></li>
                <li><Link to='/mentions-legales'>Mentions légales</Link></li>
              </ul>
            </div>

            <div className='col-md-4'>
              <h4>Informations</h4>
              <hr />
              <ul id='informations'>
                <li><i className='fas fa-map-marker' /><span>9 rue du quatre septembre,<br /> 75002 Paris,<br /> France.<br />107 allée des peupliers,<br /> 95620 Parmain (Val-d'Oise),<br /> France.</span></li>
                <li><i className='fas fa-clock' /><span>Lundi au vendredi : 9h à 18h <br /> Samedi : 14h à 18h</span></li>
                <li><i className='fas fa-envelope' /><span>francine.lampin@hypnose-venture.fr</span></li>
                <li><i className='fas fa-phone' /><span><Link to='tel:06.60.24.18.84'>06.60.24.18.84</Link></span></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <section className='badge'>
        <div className='container'>
          <div className='col-md-6 col-sm-6 col-xs-6 flex'>
            <StaticImage src='../img/certifieFooter.png' placeholder='blurred' alt='certification footer' className='center block' width={50} height={50} />
          </div>
          <div className='col-md-6 col-sm-6 col-xs-6 flex'>
            <StaticImage src='../img/ath.jpg' placeholder='blurred' alt='ATH' className='center block' width={89} height={50} />
          </div>
        </div>
      </section>

      <div id='loader' className={loaded ? 'hide' : 'loading'}>
        <div className='loading-text'>
          <span className='loading-text-words'>C</span>
          <span className='loading-text-words'>H</span>
          <span className='loading-text-words'>A</span>
          <span className='loading-text-words'>R</span>
          <span className='loading-text-words'>G</span>
          <span className='loading-text-words'>E</span>
          <span className='loading-text-words'>M</span>
          <span className='loading-text-words'>E</span>
          <span className='loading-text-words'>N</span>
          <span className='loading-text-words'>T</span>
        </div>
        <div className='sign'><h2>Hypnose Venture</h2></div>
      </div>
      <Breadcrumb />
    </>
  );
};

export default Footer;
